<template>
  <div class="background background--no-offset">
    <div class="not-found-page">
      <AppBarI18n />
      <div class="not-found-page__wrapper">
        <v-img
          :src="appLogo"
          max-width="188px"
          min-width="188px"
          alt="logo"
          contain
          class="not-found-page__logo mt-4"
        ></v-img>
        <div class="not-found-page__content">
          <v-icon size="40" color="primary" class="not-found-page__content-icon">
            {{ icons.mdiLinkVariantOff }}
          </v-icon>
          <h1 class="not-found-page__content-title">
            {{ $t('notFoundPageTitle') }}
          </h1>
          <div class="not-found-page__content-description">
            {{ $t('notFoundPageDescriptionNotLogged') }}
          </div>
          <v-btn to="/" color="primary">{{ $t('notFoundBtnNotLogged') }}</v-btn>
        </div>
      </div>
    </div>
    <Footer :with-space="false" />
  </div>
</template>

<script>
import { mdiAlert, mdiLinkVariantOff } from '@mdi/js';
import themeConfig from '@themeConfig';
import Footer from '@/components/footer/Footer.vue';
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18nLoggetOut.vue';

export default {
  components: { AppBarI18n, Footer },
  setup() {
    return {
      icons: {
        mdiAlert,
        mdiLinkVariantOff,
      },
      appLogo: themeConfig.app.logo,
    };
  },
};
</script>

<style lang="scss">
.not-found-page {
  padding: 20px;
  min-height: calc(100vh - 40px);
  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__content {
    max-width: 530px;
    margin-top: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: #2830ce;
      margin: 24px 0;
    }
    &-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #46474a;
      margin-bottom: 40px;
    }
  }
}
</style>
